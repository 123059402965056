<template>
  <div>

  </div>
  <!--  <button v-if="deferredPrompt" @click="installApp" @keypress="installApp">
      Install App!
    </button>-->
  <router-view />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import axios from 'axios';
import IndexedDB from '../utils/indexedDB';
import Api from '../utils/api';
// import slugify from slugify;
const slugify = require('slugify');

export default {
  data() {
    return {
      deferredPrompt: null,
      showInstallPrompt: false,
      questions: [],
      settings: [],
      onLine: false,
    };
  },
  computed: {
    ...mapState(['isBrowserReloaded']),
  },
  watch: {
    async onLine(val) {
      if (val) {
        console.info('🚀 ------------------------------------------🚀');
        console.info('💡 ~ file: App.vue:28 ~ you Are onLine ~ :', val);
        console.info('🚀 ------------------------------------------🚀');
        this.questions = await this.getQuestion();
        this.settings = await this.getSettings();
        await IndexedDB.insertIndexedDB({ name: 'QUESTIONS', data: this.questions });
        await IndexedDB.insertIndexedDB({ name: 'SETTINGS', data: this.settings });

        await IndexedDB.syncInsertEntries();
      } else {
        console.warn('🚀 ------------------------------------------🚀');
        console.warn('💀 ~ file: App.vue:28 ~ you Are offLine ~ :', val);
        console.warn('🚀 ------------------------------------------🚀');
      }
    },
  },
  methods: {
    ...mapMutations(['setIsBrowserReloaded']),
    installApp() {
      console.log('install requested');
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
          this.showInstallPrompt = false;
        });
      }
    },
    showEventPrompt() {
      let previousEventName = sessionStorage.getItem('eventName');
      if (!previousEventName) {
        previousEventName = 'default';
      }

      // eslint-disable-next-line no-alert
      previousEventName = prompt('Please enter event name', previousEventName);

      previousEventName = slugify(previousEventName, {
        replacement: '_', // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: false, // convert to lower case, defaults to `false`
        strict: true, // strip special characters except replacement, defaults to `false`
        trim: true, // trim leading and trailing replacement chars, defaults to `true`
      });
      sessionStorage.setItem('eventName', previousEventName);
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
    async getQuestion() {
      try {
        const { data } = await Api.get('/question');
        return data.rows;
      } catch (error) {
        console.log('🚀 ---------------------------------------------------🚀');
        console.log('🚀 ~ file: App.vue:42 ~ getQuestion ~ error:', error);
        console.log('🚀 ---------------------------------------------------🚀');
        return [];
      }
    },
    async getSettings() {
      try {
        const { data } = await Api.get('/settings');
        return data.data;
      } catch (error) {
        console.log('🚀 ---------------------------------------------------🚀');
        console.log('🚀 ~ file: App.vue:53 ~ getSettings ~ error:', error);
        console.log('🚀 ---------------------------------------------------🚀');
        return [];
      }
    },
    async asyncRegisterPageView() {
      const { data: { ip } } = await axios.get('https://api.ipify.org?format=json');
      const eventName = sessionStorage.getItem('eventName') ?? 'default';
      const payload = {
        eventName,
        ip,
        url: window.location.href,
        interaction: 'page_view_trivia',
      };

      const { data } = await Api.post('/page-view', payload);
      return data;
    },
  },
  async created() {
    window.addEventListener('beforeinstallprompt', (event) => {
      // console.log('🚀 ~ file: App.vue:44 ~ window.addEventListener ~ event:', event);
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallPrompt = true;
    });

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    const loadImages = [
      { src: '/js/app.js' },
      { src: '/css/app.css' },
      { src: '/js/runtime.js' },
      // { src: '/img/bg_app.png' },
      { src: '/img/logo_site.svg' },
      { src: '/img/arrow-rotate-left-orange.svg' },
      { src: '/img/logo_site.svg' },
      { src: '/img/qrcode.svg' },
      // { src: '/img/img1.png' },
      { src: '/img/red_turkey.8185b2f3.svg' },
      { src: '/img/gray_trukey.9a9066a8.svg' },
      { src: '/img/check.svg' },
      { src: '/img/x.svg' },
      { src: '/img/arrow-rotate-left.svg' },
      { src: '/img/decoration.svg' },
      { src: '/img/check-A.svg' },
      { src: '/img/Wrong-A.svg' },
      { src: '/img/forma.svg' },
    ];
    const questions = await IndexedDB.getIndexedDB('QUESTIONS');
    questions.forEach((question) => {
      question.answers.forEach((answer) => {
        loadImages.push({ src: `/img/images/questions/${answer.attachment}` });
      });
    });
    if (navigator.onLine) {
      IndexedDB.preloadImages(loadImages);
    }
  },
  async mounted() {
    const previousEventName = sessionStorage.getItem('eventName');
    if (!previousEventName) {
      this.showEventPrompt();
    }

    setInterval(async () => {
      await IndexedDB.syncInsertEntries();
    }, 15000);

    try {
      await this.asyncRegisterPageView();
    } catch (error) {
      console.log(error);
    }

    this.onLine = navigator.onLine;
    console.log(await IndexedDB.getIndexedDB('APEROLDB'), 'APEROLDB');
    console.log(await IndexedDB.getIndexedDB('APEROLBACK'), 'APEROLBACK');
  },

  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>

<style lang="scss">
@import 'styles/style.scss';
</style>
